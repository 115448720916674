
$white:    #FFFFFF;
$gray-100: #f2f8fc;
$gray-200: #e8f2fa;
$gray-300: #dceaf5;
$gray-400: #bfd1e0;
$gray-500: #a3b9cc;
$gray-600: #728799;
$gray-700: #39444d;
$gray-800: #262d33;
$gray-900: #20262B;
$black:    #13171a;

$orange: #F2590C;
$yellow: #FFB400;

$primary:       $orange;
$warning:       $yellow;
$dark:          $gray-900;

$headings-color:              #13171a;

$link-color:                $primary;
$link-hover-color:          adjust-color($link-color, $hue: 10);
