// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.

p, .text-justify {
    hyphens: auto;
}

code, pre, .subtext, #funtyped {
    font-family: 'Source Code Pro', 'SF Mono',
                 SFMono-Regular, ui-monospace,
                 'DejaVu Sans Mono', Menlo, Consolas, monospace;
}

.subtext {
    font-size:26pt;
    padding: 0;
    height: 3em;
    width:100%;
}

.subtext {
    font-size:26pt;
}

.svg-icon-primary {
    filter:hue-rotate(150deg);
}

.kw {
    color: $secondary !important;
}

.kw2 {
    color: $primary !important;
}

.navbar-brand {
    svg {
        height: $navbar-brand-size;
    }
}

.logo-dark, .navbar-light .navbar-brand svg {
    fill:$dark;
    stroke:$dark;
    // * {
    //     fill:$dark;
    //     stroke:$dark;
    // }
    path { stroke:$dark; }
    circle { fill:$dark; }
}

.logo-white, .navbar-dark .navbar-brand svg {
    fill:$white;
    stroke:$white;
    // * {
    //     fill:$white;
    //     stroke:$white;
    // }
    path { stroke:$white; }
    circle { fill:$white; }
}

.shape-container-secondary {
    svg {
        fill: $secondary;
    }
}

.client img {
    max-width: 8rem;
}

pre code {
    @extend .highlight;
    color: #DCDCDC;
    padding: 1.25rem!important;
}
